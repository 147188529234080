import React, { Component } from 'react'

class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        404, No Page Found
      </React.Fragment>
    );
  }
}

export default NotFound;
